.gap-2 {
    gap: 10px !important;
}

.gap-1 {
    gap: 3px !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.value-PERSON {}

.label-PERSON {
    font-size: 10px;
    background-color: #61C3FF;
    margin-left: 3px;
    padding: 0px 3px;
    /* Không cho người dùng bôi đen  */
    user-select: none;
}

.container-PERSON {
    color: black;
    background-color: #D8F3FF;
    border-radius: 2px;
    border: 1px solid #9BD4F4;
    text-align: center;
    padding: 0px 5px;
}

.container-LOCATION {
    color: black;
    background-color: #FFD8D8;
    border-radius: 2px;
    border: 1px solid #FF9B9B;
    text-align: center;
    padding: 0px 5px;
}

.label-LOCATION {
    font-size: 10px;
    background-color: #FF6161;
    margin-left: 3px;
    padding: 0px 3px;
}

.value-LOCATION {}

.container-ORGANIZATION {
    color: black;
    background-color: #FFD8FF;
    border-radius: 2px;
    border: 1px solid #FF9BFF;
    text-align: center;
    padding: 0px 5px;
}

.label-ORGANIZATION {
    font-size: 10px;
    background-color: #FF61FF;
    margin-left: 3px;
    padding: 0px 3px;
}

.value-ORGANIZATION {}

.container-MISCELLANEOUS {
    color: black;
    background-color: #D8D8FF;
    border-radius: 2px;
    border: 1px solid #9B9BFF;
    text-align: center;
    padding: 0px 5px;
}

.label-MISCELLANEOUS {
    font-size: 10px;
    background-color: #6161FF;
    margin-left: 3px;
    padding: 0px 3px;
}

.value-MISCELLANEOUS {}


/* styles.css */
.SelectionContent {
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    z-index: 999999999;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.SelectionContent[data-state='open'] {
    animation-name: slideDownAndFade;
}

.SelectionContent[data-state='closed'] {
    animation-name: slideUpAndFade;
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUpAndFade {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-2px);
    }
}
