.main-statistic {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    user-select: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: .1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-left: 8px;
    margin-right: 8px;
    width: 2800px;

    .title-statistic {
        font-size: 2em;
        font-weight: 700;
        grid-area: 1 / 1 / 2 / 3;
    }

    .main-chart {
        margin-top: 1em;
        grid-area: 2 / 1 / 3 / 2;

        .main-select {
            display: flex;
            gap: 1em;
            color: #444;
            margin-left: 0px;

            .select-filter {
                color: #444;
                padding: .5em;
                width: 200px;
            }

            .styled-select {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                border: 2px solid #007bff;
                /* Set the border color */
                color: #03101e;
                /* Set the text color */
                padding: .5em;
                width: 150px;
                font-size: 16px;
                /* Set the font size */
                cursor: pointer;
                border-radius: 10px;
                /* Add rounded corners */
                margin-left: 80px;
            }

            /* Style the arrow icon */
            .select-arrow {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                color: #007bff;
                /* Set the arrow color */
                font-size: 16px;
                /* Set the arrow size */
                pointer-events: none;
                /* Prevent the arrow from being clickable */
            }
        }
    }

    .main-table {
        grid-area: 2 / 2 / 3 / 3;
        display: flex;
        flex-direction: column;
        margin-top: 1em;

        .date-picker-table {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            width: 100%;
        }

        .tables {
            display: flex;
            justify-content: space-between;
            gap: 2em;

            .table-content {
                margin-top: 1em;
                display: flex;
                flex-direction: column;
                gap: 1em;
                overflow-x: auto;

                .title-table {
                    font-size: 1.5em;
                    font-weight: 700;
                }

                .table-container {
                    .table-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                    }

                    max-height: 200px;
                    /* Adjust the height as needed */
                    overflow-y: auto;
                }
            }
        }

    }
}

.not-data {
    font-size: 2em;
    color: red;
    text-align: center;
    align-items: center;
}